import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { LoadingButton } from '@mui/lab';
import { Checkbox, Grid, Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const RejectEvaluationRequestsForm = ({
  rejectEvaluationRequests,
  analystDescription,
  setAnalystDescription,
  columns,
  disabled,
  informClients,
  setInformClients,
  sendEmail,
  setSendEmail,
  loading,
  error,
  rows,
  openOtherRejectionReason,
  setOpenOtherRejectionReason,
  updatePreOfferEvaluationRequests,
  selectedPreOfferEvaluation,
}) => (
  <>
    <FingoDataGrid
      hideFooter
      rowHeight={50}
      rows={rows}
      columns={columns}
    />
    <Grid container direction="column" alignItems="center" spacing={1}>
      <Grid item>
        <Stack direction="row" alignItems="center">
          <Typography>Informar al cliente de la decisión tomada</Typography>
          <Checkbox
            color="primary"
            checked={informClients}
            onChange={() => setInformClients(!informClients)}
          />
          <Typography>Enviar Email de Rechazo</Typography>
          <Checkbox
            color="primary"
            checked={sendEmail}
            onChange={() => setSendEmail(!sendEmail)}
          />
        </Stack>
      </Grid>
      <Grid item sx={{ width: '70%' }}>
        <TextField
          sx={{ width: '100%' }}
          variant="outlined"
          name="rejectionComments"
          label="Comentarios de Rechazo"
          value={analystDescription}
          onChange={(e) => setAnalystDescription(e.target.value)}
          multiline
          rows={3}
        />
      </Grid>
      <Grid item sx={{ marginTop: 2 }}>
        <LoadingButton
          variant="contained"
          color={!error ? 'primary' : 'warning'}
          size="small"
          sx={{ marginRight: 1 }}
          onClick={rejectEvaluationRequests}
          disabled={disabled}
          loading={loading}
        >
          {error ? 'Error al' : ''} Confirmar Rechazo
        </LoadingButton>
      </Grid>
    </Grid>
    <FingoDialog
      key="rejected-reason"
      id="rejected-reason-dialog"
      title="Razón Rechazo"
      open={openOtherRejectionReason}
      handleClose={() => setOpenOtherRejectionReason(false)}
      maxWidth="md"
      fullWidth
    >
      <Stack sx={{ marginTop: 1 }}>
        <TextField
          required
          label="Obligatorio"
          variant="outlined"
          size="small"
          name="otherRejectionReason"
          value={rows.find((row) => row.id === selectedPreOfferEvaluation)?.otherRejectionReason}
          onChange={(e) => updatePreOfferEvaluationRequests(selectedPreOfferEvaluation, e)}
          multiline
          rows={1}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>
    </FingoDialog>
  </>
);

RejectEvaluationRequestsForm.propTypes = {
  rejectEvaluationRequests: PropTypes.func.isRequired,
  analystDescription: PropTypes.string.isRequired,
  setAnalystDescription: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  informClients: PropTypes.bool.isRequired,
  setInformClients: PropTypes.func.isRequired,
  sendEmail: PropTypes.bool.isRequired,
  setSendEmail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  openOtherRejectionReason: PropTypes.bool.isRequired,
  setOpenOtherRejectionReason: PropTypes.func.isRequired,
  updatePreOfferEvaluationRequests: PropTypes.func.isRequired,
  selectedPreOfferEvaluation: PropTypes.string.isRequired,
};

export default RejectEvaluationRequestsForm;
