import React from 'react';
import moment from 'moment';
import { DateTimeCell, ActionDrawerCell, CompanyProfileCell } from '@fingo/lib/components/cells';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import { Chip } from '@mui/material';
import RiskCommentsActions from '@fingo/lib/components/drawer/RiskCommentsActions';
import { formatRut } from '@fingo/lib/helpers';

const blacklistColumns = () => ([
  {
    field: 'name',
    type: 'string',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
    sortable: true,
    flex: 2,
  },
  {
    field: 'type',
    type: 'node',
    headerName: 'Tipo',
    headerAlign: 'left',
    align: 'center',
    renderCell: ({ row }) => (
      <Chip
        label={row?.riskBlacklist?.blacklistType}
        color={row?.riskBlacklist?.blacklistType === 'BLACKLIST' ? 'error' : 'warning'}
        size="small"
      />
    ),
    sortable: true,
    flex: 0.5,
  },
  {
    field: 'riskBlacklist__createdAt',
    type: 'date',
    headerName: 'Fecha Ingreso Blacklist',
    sortable: true,
    filterable: false,
    renderCell: ({ row }) => (
      <DateTimeCell fullDate={moment(row?.riskBlacklist?.createdAt)} />
    ),
    flex: 1,
  },
  {
    field: 'comments',
    headerName: 'Comentarios',
    type: 'string',
    sortable: false,
    filterable: false,
    maxWidth: 90,
    flex: 1,
    renderCell: ({ row }) => (
      <ActionDrawerCell
        title={row?.name}
        subtitle={formatRut(row?.rut)}
        actions={row?.riskBlacklist?.comments ? [row?.riskBlacklist?.comments] : []}
        headerComponent={DrawerHeader}
        contentComponent={RiskCommentsActions}
        masterEntityId={row?.id}
      />
    ),
  },
]);

export default blacklistColumns;
