import React from 'react';
import CreditLineEvaluationResolutionCell from '../components/credit-line-evaluation-requests/CreditLineEvaluationResolutionCell';
import CreditLinePastEvaluation from '../components/credit-line-evaluation-requests/CreditLinePastEvaluations';

const CreditLineEvaluationRequestsColumns = [
  {
    field: 'resolutionStatus',
    headerName: 'Resolución',
    align: 'center',
    maxWidth: 100,
    sortable: false,
    filterable: false,
    valueGetter: ({ row }) => row.creditLineEvaluationRequests.status,
    renderCell: ({ row }) => (
      <CreditLineEvaluationResolutionCell
        evaluationId={row.creditLineEvaluationRequests.id}
        creditLineLimit={row.limitAmount}
      />
    ),
  },
  {
    field: 'evaluations',
    headerName: 'Pasadas',
    align: 'center',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <CreditLinePastEvaluation masterEntityId={row.id} />
    ),
  },
];

export default CreditLineEvaluationRequestsColumns;
