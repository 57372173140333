import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState } from '@fingo/lib/hooks';
import { Typography, FormControl, Select, MenuItem, TextField, Button, Stack, Box, Divider } from '@mui/material';
import {
  GET_RESTRICTIONS_UNCREATED,
  CREATE_COMPANY_RESTRICTION,
  GET_MASTER_ENTITY_RESTRICTIONS,
} from '@fingo/lib/graphql';
import { LoadingButton } from '@mui/lab';
import dynamicRestrictionsField from '../../../constants/restrictions-fields';
import RestrictionsHistory from '../RestrictionHistory';

const AddRestrictionsDialog = ({ companyId, companyName, documentType, disabled }) => {
  const [openRestriction, toggleOpenRestriction] = useBooleanState();
  const [restriction, setRestriction] = useState('');
  const [description, setDescription] = useState('');
  const [additionalFields, setAdditionalFields] = useState({
    contractType: null,
    endorser: null,
    triggeringDocumentIds: [],
    triggeringDocumentType: '',
  });
  // Add restriction
  const [addCompanyRestriction, { loading, error }] = useMutation(
    CREATE_COMPANY_RESTRICTION,
    {
      variables: {
        requestRestriction: [
          {
            companyId,
            restriction,
            description,
            restrictedDocumentType: documentType,
            ...additionalFields,
          },
        ],
      },
      refetchQueries: [
        GET_RESTRICTIONS_UNCREATED,
        GET_MASTER_ENTITY_RESTRICTIONS,
      ],
      onCompleted: () => {
        setDescription('');
        setRestriction('');
        setAdditionalFields({
          contractType: null,
          endorser: null,
          triggeringDocumentIds: [],
          triggeringDocumentType: null,
        });
      },
    },
  );

  // Obtain all the available restrictions
  const { data } = useQuery(
    GET_RESTRICTIONS_UNCREATED,
    {
      variables: {
        companyId,
      },
      skip: !companyId,
    },
  );
  const restrictions = useMemo(() => data?.getCompanyRestrictionsUncreated || [], [data]);

  // Reset additional fields
  useEffect(() => {
    const selectedFields = dynamicRestrictionsField[restriction] || [];
    const documentTypeField = selectedFields.find((field) => field.documentType)?.documentType || '';

    setAdditionalFields({
      contractType: null,
      endorser: null,
      triggeringDocumentIds: [],
      triggeringDocumentType: documentTypeField,
    });
  }, [restriction]);

  // Save additional fields info
  const handleFieldChange = (fieldName, value) => {
    setAdditionalFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  return (
    <>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={toggleOpenRestriction}
        disabled={disabled}
      >
        Añadir Restricciones
      </Button>
      <FingoDialog
        key="add-restrictions-dialog"
        id="add-restrictions-dialog"
        title={`Restricciones ${companyName}`}
        open={openRestriction}
        handleClose={toggleOpenRestriction}
        maxWidth="xl"
        fullWidth
        paddingContent={3}
      >
        <Box display="flex" height="100%">
          <Box flex={1} padding={2}>
            <RestrictionsHistory companyId={companyId} />
          </Box>

          <Divider orientation="vertical" flexItem />

          <Box flex={1} padding={2} sx={{ marginLeft: '16px' }}>
            <Stack spacing={2}>
              <Typography variant="h5" align="center" gutterBottom>
                Agregar restricción
              </Typography>
              <Typography>
                Seleccione el tipo de restricción
              </Typography>
              <FormControl fullWidth>
                <Select
                  name="ratification"
                  value={restriction}
                  onChange={(e) => setRestriction(e.target.value)}
                >
                  {restrictions.map((type) => (
                    <MenuItem
                      key={type.value}
                      value={type.value}
                      disabled={type.created}
                    >
                      {type.label}{type.created && ' (Restricción ya levantada)'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {dynamicRestrictionsField[restriction]?.map((fieldConfig) => {
                const Component = fieldConfig.component;

                return (
                  <Component
                    key={restriction}
                    label={fieldConfig.label}
                    value={additionalFields[fieldConfig.fieldName]}
                    onChange={(value) => handleFieldChange(fieldConfig.fieldName, value)}
                    options={fieldConfig.options || []}
                    companyId={companyId}
                  />
                );
              })}

              <Typography>
                Descripción adicional
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
              <Stack direction="row" spacing={1} justifyContent="center">
                <LoadingButton
                  variant="contained"
                  size="small"
                  disabled={!restriction || (restriction === 'OTHER' && !description)}
                  color={error ? 'warning' : 'primary'}
                  loading={loading}
                  onClick={() => addCompanyRestriction()}
                >
                  {error ? 'Error al crear restricción' : 'Agregar'}
                </LoadingButton>
                <Button
                  variant="contained"
                  size="small"
                  onClick={toggleOpenRestriction}
                >
                  Cancelar
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </FingoDialog>
    </>
  );
};

AddRestrictionsDialog.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AddRestrictionsDialog;
