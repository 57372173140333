import { useQuery } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { GET_RULE_RESULTS } from '@fingo/lib/graphql';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Tab, Tabs, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import riskEvaluationResultsColumns from '../../../constants/risk-evaluations-results-columns';

const BaseRiskResult = ({
  riskEvaluation, documentId, documentType, selectedModel,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { data: riskData, loading: loadingRiskData } = useQuery(GET_RULE_RESULTS, {
    variables: {
      documentId,
      modelName: documentType,
    },
  });
  const riskResults = riskData?.getRuleResults || [];
  const retrieveNamesToFilter = useCallback((index) => (
    {
      0: ['client', 'client_debtor'],
      1: ['debtor', 'client'],
      2: ['client_debtor', 'debtor'],
    }[selectedTab][index]
  ), [selectedTab]);

  const filterBySelectedModel = useCallback(
    (resultsList) => resultsList.filter(
      (result) => result.modelType === selectedModel,
    ).filter((rule) => rule.name.startsWith(retrieveNamesToFilter(0))
    && !rule.name.startsWith(retrieveNamesToFilter(1))) || [],
    [selectedModel, retrieveNamesToFilter],
  );

  const rasResultColor = useCallback((rasResult) => {
    if (rasResult === null) return null;
    if (rasResult < 0.5) return red[700];
    return green[700];
  }, []);

  const retrieveRASIcon = useCallback((result) => (
    <Typography sx={{ color: rasResultColor(result), fontWeight: 'bold' }}>
      {result}
    </Typography>
  ), [rasResultColor]);

  const retrieveTreeIcon = useCallback((result) => (
    result ? <CheckCircle sx={{ color: green[700] }} /> : (<Cancel sx={{ color: red[700] }} />)
  ), []);

  const retrieveTabIcon = useCallback((tab) => {
    const riskResultByModel = {
      Tree: {
        client: 'treeClientResult',
        debtor: 'treeDebtorResult',
        clientDebtor: 'treeClientDebtorResult',
      },
      RAS: {
        client: 'rasClientResult',
        debtor: 'rasDebtorResult',
        clientDebtor: 'rasClientDebtorResult',
      },
    }[selectedModel][tab];
    if (selectedModel === 'RAS') {
      return retrieveRASIcon(riskEvaluation?.[riskResultByModel]);
    }
    return retrieveTreeIcon(riskEvaluation?.[riskResultByModel]);
  }, [selectedModel, retrieveRASIcon, retrieveTreeIcon]);

  return (
    <>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={(_, value) => {
          setSelectedTab(value);
        }}
      >
        <Tab
          label="Cliente"
          icon={retrieveTabIcon('client')}
        />
        <Tab
          label="Deudor"
          icon={retrieveTabIcon('debtor')}
        />
        <Tab
          label="Relación"
          icon={retrieveTabIcon('clientDebtor')}
        />
      </Tabs>
      <FingoDataGrid
        rowHeight={50}
        rowCount={filterBySelectedModel(riskResults).length}
        rows={filterBySelectedModel(riskResults)}
        columns={riskEvaluationResultsColumns(selectedModel)}
        page={0}
        pageSize={filterBySelectedModel(riskResults).length}
        loadingWithSkeleton={loadingRiskData}
      />
    </>
  );
};

BaseRiskResult.propTypes = {
  riskEvaluation: PropTypes.shape({
    treeClientDebtorResult: PropTypes.bool.isRequired,
    treeClientResult: PropTypes.bool.isRequired,
    treeDebtorResult: PropTypes.bool.isRequired,
    rasClientDebtorResult: PropTypes.number.isRequired,
    rasClientResult: PropTypes.number.isRequired,
    rasDebtorResult: PropTypes.number.isRequired,
  }),
  documentId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  selectedModel: PropTypes.oneOf(['invoice', 'purchaseorder']).isRequired,
};

BaseRiskResult.defaultProps = {
  riskEvaluation: null,
};

export default BaseRiskResult;
