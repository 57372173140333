import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, CircularProgress, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useQuery } from '@apollo/client';
import { COMPANY_LEGAL_REPRESENTATIVES } from '@fingo/lib/graphql';
import { formatRut } from '@fingo/lib/helpers';

const LegalRepresentativeAutocomplete = ({ label, companyId, onChange }) => {
  const { data, loading } = useQuery(COMPANY_LEGAL_REPRESENTATIVES, {
    variables: { masterEntityId: companyId },
    skip: !companyId,
  });

  return (
    <Box mt={1}>
      <Typography mb={1}>{label}</Typography>
      <FormControl fullWidth>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Autocomplete
            options={data?.getMasterEntity?.company?.legalRepresentative || []}
            getOptionLabel={(rep) => `${rep.relatedPerson?.name} | ${formatRut(rep.relatedPerson?.rut)}`}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    height: '35px',
                    padding: 0,
                  },
                }}
              />
            )}
            onChange={(event, value) => onChange(value ? value.id : '')}
          />
        )}
      </FormControl>
    </Box>
  );
};

LegalRepresentativeAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LegalRepresentativeAutocomplete;
