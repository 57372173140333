import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, Select, MenuItem, Typography } from '@mui/material';

const ContractTypeSelect = ({ label, value, options, onChange }) => (
  <Box mt={1}>
    <Typography mb={1}>{label}</Typography>
    <FormControl fullWidth>
      <Select
        name="contractType"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

ContractTypeSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ContractTypeSelect;
