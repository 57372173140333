import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Typography, Box } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { RUN_RISK_PREDICTION_CONTRIBUTION, GET_RISK_PREDICTION_RESULTS } from '@fingo/lib/graphql';
import { LoadingButton } from '@mui/lab';
import RiskPredictionsColumns from '../../../constants/risk-predictions-columns';

const predictionTypeToLabel = {
  OFFERED: 'APROBAR',
  REJECTED: 'RECHAZAR',
};
const predictionBooleanToLabel = {
  OFFERED: 'NO',
  REJECTED: 'SI',
};

const RiskPredictionResults = ({ riskEvaluationId }) => {
  const [predictionOutput, setPredictionOutput] = useState(null);
  const [unknownResult, setUnknownResult] = useState(false);
  const { loading } = useQuery(GET_RISK_PREDICTION_RESULTS, {
    variables: { riskEvaluationId },
    onCompleted: ({ getRiskPredictionResults }) => {
      if (!getRiskPredictionResults.length) return;
      if (
        getRiskPredictionResults[0].prediction
         === getRiskPredictionResults[0].predictionModel.predictionType.resultEnum
      ) setPredictionOutput(getRiskPredictionResults[0]);
      else if (
        getRiskPredictionResults[1].prediction
         === getRiskPredictionResults[1].predictionModel.predictionType.resultEnum
      ) setPredictionOutput(getRiskPredictionResults[1]);
      else {
        const prediction = getRiskPredictionResults.filter((result) => result.predictionModel.predictionType.resultEnum === 'OFFERED');
        setUnknownResult(true);
        setPredictionOutput(prediction?.[0] ?? null);
      }
    },
  });
  const [retrieveContribution, { loading: retrievingContribution },
  ] = useMutation(RUN_RISK_PREDICTION_CONTRIBUTION, {
    variables: {
      riskPredictionResultId: predictionOutput?.id,
    },
  });
  const pinkLabel = useCallback((value) => (
    <Box
      fontWeight="fontWeightMedium"
      display="inline"
      sx={{ color: 'primary.main' }}
    >
      {value}
    </Box>
  ), []);
  const predictionComponent = useMemo(() => {
    if (predictionOutput?.variablesContribution?.length) return null;
    return (
      <LoadingButton
        variant="contained"
        color="primary"
        loading={retrievingContribution}
        onClick={retrieveContribution}
      >
        Correr contribución
      </LoadingButton>
    );
  }, [retrievingContribution, predictionOutput, retrieveContribution]);
  const HeaderComponent = () => {
    if (!unknownResult) return null;
    return (
      <Typography variant="h6">
        El modelo{pinkLabel(' NO OBTUVO UNA PREDICCIÓN PRECISA')}. Se recomienda revisar la evaluación de forma manual.
      </Typography>
    );
  };
  const ResultsComponent = () => {
    if (unknownResult) {
      return (
        <Typography variant="h6">
          El modelo {pinkLabel(` DE ACEPTACIÓN OBTUVO UN PUNTAJE DE ${predictionOutput?.offeredValue.toFixed(4)}`)}. Cuando el modelo entrega un valor mayor o igual a{pinkLabel(` ${predictionOutput?.thresholdUsed.toFixed(4)}`)}, hay una alta probabilidad que la factura{pinkLabel(' NO ')}tenga problemas
        </Typography>
      );
    }
    return (
      <Typography variant="h6">
        El modelo {pinkLabel(` RECOMIENDA ${predictionTypeToLabel[predictionOutput?.prediction]} `)} con un puntaje de{pinkLabel(` ${predictionOutput?.offeredValue.toFixed(4)}`)}. Cuando el modelo entrega un valor mayor o igual a{pinkLabel(` ${predictionOutput?.thresholdUsed.toFixed(4)}`)}, hay una alta probabilidad que la factura {pinkLabel(` ${predictionBooleanToLabel[predictionOutput?.prediction]} `)} tenga problemas
      </Typography>
    );
  };
  if (loading) return <CircularProgress />;
  if (!predictionOutput) return <Typography>No se han corrido los modelos predictivos.</Typography>;
  return (
    <>
      <HeaderComponent />
      <ResultsComponent />
      {predictionComponent}
      {predictionOutput?.variablesContribution.length ? (
        <Box>
          <FingoDataGrid
            rows={predictionOutput.variablesContribution}
            columns={RiskPredictionsColumns}
            serverFilters={false}
            hideFooter
            sx={{ minHeight: 250 }}
          />
        </Box>
      ) : <></>}
    </>
  );
};

RiskPredictionResults.propTypes = {
  riskEvaluationId: PropTypes.string,
};

RiskPredictionResults.defaultProps = {
  riskEvaluationId: null,
};

export default RiskPredictionResults;
