import React from 'react';
import moment from 'moment';
import { CompanyProfileCell } from '@fingo/lib/components/cells';
import { formatMoney } from '@fingo/lib/helpers';
import CreditLineSuggestion from '../components/credit-line-evaluation-requests/CreditLineSuggestion';

export const HEADER_TITLE = 'Evaluaciones de líneas de crédito';

export const COLUMNS = [
  {
    field: 'masterEntity_Name',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
    sortable: true,
    flex: 2,
  },
  {
    field: 'requestDate',
    headerName: 'Fecha de solicitud',
    valueGetter: ({ row }) => row.creditLineEvaluationRequests.requestDate,
    valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY hh:mm A'),
    width: 150,
  },
  {
    field: 'suggestion',
    headerName: 'Sugerencia',
    valueGetter: ({ row }) => row.creditLineEvaluationRequests.creditlinesuggestionSet[0]?.amount,
    renderCell: CreditLineSuggestion,
    width: 150,
  },
  {
    field: 'limitAmount',
    type: 'number',
    headerName: 'Linea total',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.creditLine?.limitAmount,
    valueFormatter: ({ value }) => (value ? `$${formatMoney(value)}` : '-'),
    width: 120,
  },
  {
    field: 'creditUsed',
    type: 'number',
    headerName: 'Linea usada',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.creditLine?.creditUsed,
    valueFormatter: ({ value }) => (value ? `$${formatMoney(value)}` : '-'),
    width: 120,
  },
  {
    field: 'approvedAmount',
    type: 'number',
    headerName: 'Monto',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.creditLineEvaluationRequests.approvedAmount,
    valueFormatter: ({ value }) => (value ? `$${formatMoney(value.amount)}` : '-'),
    width: 120,
  },
  {
    field: 'comment',
    type: 'string',
    headerName: 'Comentario',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.creditLineEvaluationRequests.comment,
    valueFormatter: ({ value }) => value,
    width: 160,
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Solución',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.creditLineEvaluationRequests.status,
    valueFormatter: ({ value }) => value,
    width: 160,
  },
  {
    field: 'currency',
    type: 'string',
    headerName: 'Divisa',
    headerAlign: 'left',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.creditLine?.currency?.isoCode,
    valueFormatter: ({ value }) => value,
    width: 80,
  },
];
